// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { Box } from '@chakra-ui/react';
import flowRight from 'lodash/flowRight';
import { NextPage } from 'next';
import { withApollo } from '~/common/apollo';
import { withSSAppRedirect } from '~/common/apollo/withProps/withSSAppRedirect';
import { withSSCookie } from '~/common/apollo/withProps/withSSCookie';
import { AuthContext, AuthContextProps } from '~/containers/common/AuthContext';
import { SEO } from '~/containers/common/SEO';
import { NoticeJavascriptDisabled } from '~/containers/layouts/app/NoticeJavascriptDisabled';
import { NoticeStack } from '~/containers/layouts/app/NoticeStack';

/**
 * Login
 * If login query parameters exist, the login query is executed here and the user is redirected to the application
 * Otherwise, they are shown the login form
 */
const Page: NextPage<AuthContextProps> = ({ ...props }) => {
  return (
    <>
      <SEO
        title="Login"
        description="Access your curated bookmarks effortlessly. Log in to Niphtio for intuitive and organized bookmarks synchronized across all your devices. Log in now!"
      />
      <Box zIndex={10000} pos={'relative'}>
        <NoticeStack>
          <noscript>
            <NoticeJavascriptDisabled />
          </noscript>
        </NoticeStack>
      </Box>

      <AuthContext {...props}></AuthContext>
    </>
  );
};

export const getServerSideProps = flowRight(withSSCookie, withSSAppRedirect)();

export default flowRight(withApollo)(Page);
